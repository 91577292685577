import React from 'react'
import { graphql } from 'gatsby'
import { Row } from 'reactstrap'
import cheerio from 'cheerio'
import classNames from 'classnames'
import PageHelmet from '../components/PageHelmet'
import SubCategories from '../components/SubCategories'
import wordLinks from '../utils/wordLinks'
import SidebarLinksForInterviewAdvice from '../components/SidebarLinksForInterviewAdvise'
import styles from './content.module.scss'
import BuyBox from '../components/BuyBox'

const Category = ({
  data: { category, subCategories: subCats },
  location,
  pageContext,
}) => {
  const subCategories = subCats.edges.filter(
    ({ node }) => node.category.slug === category.slug,
  )

  // @TODO: apply word links here
  // Current implementation is not working because it depends on libs which work on backend only.
  const { words } = pageContext
  const htmlBody =
    category.body && wordLinks(cheerio.load(category.body), words)

  return (
    <>
      <PageHelmet
        {...{
          title: category.metaTitle || category.title,
          description: category.metaDescription && category.metaDescription,
          url: `https://www.wikijob.co.uk${location.pathname}`,
        }}
      />
      <Row>
        <div className="content col-lg-8">
          <h1 className="main-heading" style={{ paddingTop: '0' }}>
            {category.title}
          </h1>
          <div className="under-heading-line" />

          {subCategories.length > 0 && (
            <SubCategories
              {...{
                title: category.title,
                categories: subCategories,
                urlPrefix: `/content/${category.slug}`,
              }}
            />
          )}
          {htmlBody && (
            <div
              className={classNames(styles.content, 'mediavineAdsInContent')} // fixed class for Mediavine, don't change
              dangerouslySetInnerHTML={{ __html: htmlBody }}
            />
          )}
        </div>
        <div
          className="col-lg-4"
          style={{ paddingBottom: '60px', paddingTop: '0' }}
        >
          {location.pathname.match('interview-advice') && (
            <div style={{ marginTop: '100px' }}>
              <SidebarLinksForInterviewAdvice />
            </div>
          )}
          {location.pathname.match('aptitude-test') && (
            <BuyBox location={location} positionTop="200px" zIndex="1" />
          )}
        </div>
      </Row>
    </>
  )
}

export default Category

export const query = graphql`
  query($id: String!) {
    category: datoCmsCategory(id: { eq: $id }) {
      id
      title
      slug
      secondMenu
      metaTitle
      metaDescription
      body
    }
    subCategories: allDatoCmsSubcategory(limit: 100) {
      edges {
        node {
          id
          title
          slug
          category {
            slug
          }
          coverImage {
            fluid(
              imgixParams: {
                auto: "compress, format, enhance"
                fit: "fill"
                w: "356"
                h: "143"
              }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`
