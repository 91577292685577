import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'
import Img from 'gatsby-image'
import styles from './subcategory.module.scss'

const fallbackImage =
  'https://www.datocms-assets.com/7756/1600624248-wikijob-large-2.png?w=220,auto=format,compress'

function itemImage(coverImage) {
  return coverImage ? (
    <Img fluid={coverImage.fluid} className={styles.articleCoverImage} />
  ) : (
    <div
      className={styles.articleCoverImage}
      style={{ backgroundImage: `url(${fallbackImage})` }}
    />
  )
}

const SubCategories = ({ categories, urlPrefix }) => (
  <Row className={styles.subcategoriesRow}>
    {categories
      ? categories
          .sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0))
          .map(({ node: { id, slug, title, coverImage } }) => (
            <Col
              xs={12}
              md={6}
              lg={4}
              key={id}
              className={styles.subcategoryColumn}
            >
              <Link className={styles.articleLink} to={`${urlPrefix}/${slug}`}>
                {itemImage(coverImage)}
                <h5 className={styles.articleTitle}>{title}</h5>
              </Link>
            </Col>
          ))
      : null}
  </Row>
)

SubCategories.propTypes = {
  categories: PropTypes.array.isRequired,
  urlPrefix: PropTypes.string.isRequired,
}

export default SubCategories
