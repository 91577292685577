module.exports = ($cheerio, words, urlProcessingHook) => {
  function transform($cheerio, node) {
    const children = node.children('main,article,div,p,li,ul,ol,span')
    children.each(function () {
      transform($cheerio, $cheerio(this))

      const textNodes = this.children.filter((node) => node.type === 'text')
      textNodes.forEach((node) => {
        const nodeValue = $cheerio(node).text()
        for (let i = 0; i < words.length; ++i) {
          const re = RegExp(`(${words[i][1]})(?![^<]*>|[^<>]*</)`, 'ig')
          const match = nodeValue.match(re)
          if (!match)
            continue

          let url = words[i][0];

          const newNodes = []

          const hrefURL = urlProcessingHook ? urlProcessingHook(url) : url

          nodeValue.split(re).forEach((item) => {
            const newNode = match.includes(item)
              ? `<a rel="nofollow" target='_blank' class="Linkly" href="${hrefURL}">${item}</a>`
              : `${item}`

            newNodes.push(newNode)
          })

          if (newNodes) {
            $cheerio(node).replaceWith(newNodes)
          }
        }
      })
    })
  }

  transform($cheerio, $cheerio('body'));
}
